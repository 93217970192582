export type Problem = {
  type: string;
  title: string;
  status: number;
  detail: string;
  instance?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & Record<string, any>;

export type BackendErrorType = {
  problem: Problem;
  code: BackendError;
  type?: string;
};

export enum BackendError {
  _InternalError = -2,
  _UnknownError = -1,
  BackendError = 100,
  InvalidCaptcha = 101,
  InvalidClient = 102,

  UserNotVerified = 110,
  MissingCookie = 111,
  UserNotFound = 112,
  PasswordDoNotMatch = 113,
  SessionIdNotMatching = 114,
  InvalidEmail = 115,
  InvalidUsername = 116,
  InvalidPassword = 117,
  InvalidBetaCode = 118,
  InvalidTerms = 119,

  EmailAlreadyTaken = 120,
  UsernameAlreadyTaken = 121,
  AccountFailedToVerify = 122,
  AlreadyVerified = 123,

  DeletingUserFailed = 135,

  GameNotFound = 140,
  GameNameMissing = 141,
  JoinFailed = 142,
  OpcodeMissing = 143,
  InvalidOpcode = 144,
  AlreadyPlaying = 146,
  GameIsFull = 147
}

// TODO: add docs url
export const createEmptyProblem = (): Problem => ({
  type: '',
  title: 'Internal frontend error',
  detail: 'This is an empty problem generated by the frontend. This probabatlky happened to in cause of some APi related frontend error.',
  status: 0
});

export const parseBackendError = (text: string): BackendErrorType => {
  try {
    const content = JSON.parse(text);
    return {
      code: content.errorCode ?? BackendError._UnknownError,
      problem: {
        ...content
      },
      type: content.errorCodeName ?? ''
    };
  } catch (e: unknown) {
    return {
      code: BackendError._UnknownError,
      problem: createEmptyProblem(),
      type: JSON.stringify(e)
    };
  }
};

export const createEmptyBackendError = (): BackendErrorType => ({
  code: BackendError._UnknownError,
  type: '',
  problem: createEmptyProblem()
});

export const getBackendErrorTranslation = (err: BackendErrorType): string => {
  return `errors.backend.${err.code}`;
};
